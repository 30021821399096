<template>
  <v-container fluid>
    <!--Select Customer-->
    <v-row>
      <v-col>
        <CustomerPaymentsAutocomplete
          @passBack="$emit('passBack', $event)"
          @passCustomerSelect="customer = $event"
        />
      </v-col>
    </v-row>
    <!--Customer Form-->
    <v-row v-if="customer">
      <v-col>
        <CustomerPaymentsForm
          :transaction="transaction"
          @passBack="$emit('passBack', $event)"
          :customer="customer"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "CustomerPaymentsWrapper",
  props: ["transaction"],
  components: {
    CustomerPaymentsAutocomplete: () =>
      import("./CustomerPaymentsAutocomplete"),
    CustomerPaymentsForm: () => import("./CustomerPaymentsForm.vue"),
  },
  data() {
    return {
      customer: null,
    };
  },
};
</script>